import React, { useRef, useState } from "react";
import "../components/payments.css";
import {
    REACT_APP_CLAVESITIO,
    REACT_APP_CLAVESECRETA,
} from "../store/consts/consts";
import Reaptcha from "reaptcha";
import Swal from "sweetalert2";
import appsnew from "../images/preciosapp.jpg";
import apptv from "../images/iconmplay.png";
import appfen from "../images/iconfencin.png";
import appsfen from "../images/02newdispo.jpg";
import appsmed from "../images/mediafire.jpg";
import appscin from "../images/fenmed.jpg";
import ReactPlayer from "react-player";
import Writehorario from "./Horario";
import Helmet from 'react-helmet';

const InstallApp = () => {
    const [term, setTerm] = useState("");
    const [text, setText] = useState("");
    const [errmessage, setErrMessage] = useState("por favor complete el captcha");

    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then((res) => {
            setCaptchaToken(res);
        });
    };

    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            /* ✅ Copied successfully */
        } catch (e) {
            /* ❌ Failed to copy (insufficient permissions) */
        }
    }
    const writeatencion = () => {
        return (
            <>
                <div className="contentatencion">
                    <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
                </div>
            </>
        );
    };

    const handleSubmit = (event) => {
        // Preventing the page from reloading
        event.preventDefault();

        // Do something
        // alert("TOKEN Captcha", captchaToken, "captcharef", captchaRef);

        //
        captchaToken === null
            ? Swal.fire({
                title: "Atención " + errmessage,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `Error`,
                icon: "error",
            })
            : window.open("http://mtd.llc/calibraciones.php?email=" + term, "_blank");
        setCaptchaToken(null);
        setErrMessage("Debe refrescar esta página");
    };

    return (

        <div>
            <Helmet>
                <title>Install Apps</title>
            </Helmet>
            <Writehorario />
            {writeatencion()}
            <div class="card text-white bg-primary mb-3">
                <div class="card-header">Instrucciones de instalación</div>
                <div class="card-body">
                    <h5 class="card-title">Cómo instalar apps en equipos andorid</h5>
                    <p class="card-text">
                        Instalar apps en Convertidor android (TV Box), tablets android,
                        celulares android y emuladores Android para Windows (recomendado Nox
                        Player).{" "}
                        <a href="#tvbox" className="text-light">
                            Instrucciones
                        </a>
                        <br />
                        Si la app de T.V- le da errores pruebe realizar lo siguiente.{" "}
                        <a href="#error" className="text-light">
                            Probar
                        </a>
                        <br />
                        Instalar apps en en TELEVISORES y dispositivos AndroidTV / GoogleTV / Fire TV.{" "}
                        <a href="#androidtv" className="text-light">
                            Descargue PDF
                        </a>
                        <br />
                    </p>
                </div>
            </div>
            <div id="tvbox" className="row">
                <div class="card border-primary col-md-6">
                    <div className="card-header"><h3>Descargar app de Tel3</h3></div>
                    <div className="showcase">
                        <div className="contentinstallapp">
                            <p className="text-dark bg-info">APP de t3le ♾️ OFICIAL:</p>
                            <b>a)</b> ir al navegador web 🌐 y
                            escribe el siguiente link, en la barra de direcciones{" "}
                            <button
                                className="boton"
                                onClick={() => window.open("https://bit.ly/multivision-play")}
                            >
                                👉 https://bit.ly/multivision-play 👈
                            </button>{" "}
                            v6.0.6
                            <br />
                            (NO usar la barra de búsqueda).
                            <br />
                            En <b>celu o tablet android</b>, solo{" "}
                            <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.
                            <br />
                            <b>b)</b>Aceptar la descarga de archivo .apk
                            <br />
                            <b>c)</b> Al terminar de descargar, clic en abrir/open (para
                            instalar) en mensaje emergente. <br />
                            Si el mensaje se borra (abrir/open) muy rápido, salga del
                            navegador y valla a las apps del equipo, buscar file
                            manager/archivos y alli la carpeta download y buscar el archivo
                            multivision.apk <br />
                            (si tiene varios archivos llamados tv con números entre
                            paréntesis, aquel con el numero mas grande será el último bajado).
                            <br />
                            <b>d)</b> abre la app e inicia sesión
                            <br />
                            <b>e)</b> A disfrutar!
                            <div className="formulario">
                                <ReactPlayer
                                    url="https://km210.com/apk/instalarentvbox.mp4"
                                    type="video/mp4"
                                    width="500px"
                                    height="300px"
                                    controls={true}
                                />
                                Instrucciones en video
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-primary col-md-6">
                    <div className="card-header"><h3>Descargar App de contenido extra</h3></div>
                    <div className="contentinstall">
                        <p className="text-dark bg-info">APP de 🎞️🍿📽️🎬</p>
                        <b>a)</b> ir al navegador web 🌐 y escribe el
                        siguiente link, en la barra de direcciones{" "}
                        <button
                            className="boton"
                            onClick={() => window.open("https://bit.ly/cinefenix")}
                        >
                            👉 https://bit.ly/cinefenix 👈
                        </button>{" "}
                        v4.2.9
                        <br />
                        (NO usar la barra de búsqueda).
                        <br />
                        En <b>celu o tablet android</b>, solo{" "}
                        <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.
                        <br />
                        <b>b)</b> Aceptar la descarga del archivo terminado en .apk
                        <br />
                        <b>c)</b> Al terminar de descargar, clic en abrir/open (para
                        instalar) en mensaje emergente. <br />
                        Si el mensaje se borra (abrir/open) muy rápido, salga del navegador
                        y valla a las apps del equipo, buscar file manager/archivos y alli
                        la carpeta download y buscar el archivo bajado en paso previo
                        <br />
                        (si tiene varios archivos llamados tv con números entre paréntesis,
                        aquel con el numero mas grande será el último bajado).
                        <br />
                        <b>d)</b> Solicitanos usuario <br />
                        <b>e)</b> abre la app e inicia sesión
                        <br />
                        <b>f)</b> A disfrutar!
                        <br />
                    </div>
                </div>
            </div>
            <div id="androidtv" className="contentpdf">
                Para instalar las apps en <b>TELEVISORES</b> y dispositivos{" "}
                <b>AndroidTV / GoogleTV / Fire TV</b> descargue el siguiente{" "}
                <a
                    href="https://sib-2000.com.ar/docs/newtuto.pdf"
                    download="newtuto.pdf"
                    target="_blank" rel="noopener noreferrer"
                >
                    <button className="boton">Documento PDF</button>
                </a>
            </div>
            <br />
            <div className="contentnota">
                NOTA: <b>No nos hacemos responsables</b> por cualquier inconveniente *o
                daño al equipo* al instalar la app. Si usted no es avezado en el tema,
                le solicitamos que, *por favor contacte a su asesor/técnico IT de
                cabacera*, para poder recibir asesoramiento en los pasos de esta guia.
            </div>
            <div className="contentinstall">
                <h1>SOLO: Si tiene problemas para visualizar el contenido de la app</h1>
                Chequear/actualizar servers en su wifi
                <br />
                Ingrese su usuario de la app y de clic/tap en chequear
                <br />
                Este paso intentará refrescar nuestros servers en su conexion de
                internet
                <br />
                (haga esto en su red wifi)
                <div className="formulario" id="error">
                    <form onSubmit={handleSubmit}>
                        <label>
                            Usuario:
                            <input
                                value={term}
                                onChange={(e) => setTerm(e.target.value)}
                                type="text"
                                placeholder="usuario@de.app"
                                className="input"
                                required
                            ></input>
                        </label>
                        <Reaptcha
                            sitekey={REACT_APP_CLAVESITIO}
                            ref={captchaRef}
                            onVerify={verify}
                        />
                        <button type="submit" className="btn-success">
                            Chequear
                        </button>
                    </form>
                </div>
            </div>
            <hr />
            {/*         {writehora()} */} <Writehorario />
            {writeatencion()}
        </div>
    );
};

export default InstallApp;
