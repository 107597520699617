const Writehorario = () => {
    return (<>
        <div className='contenthora'>
            <h5>🕘 Horario de atención 🕘</h5>
            <div className='contentatencion'>
                <b>👇Atención UNICAMENTE dentro del horario👇</b>
            </div>
            <div>Lunes y viernes<br />
                9:30 a 12:30 - 16:30 a 19:30<br />
            </div>
            <div><b>Sabados, domingos y feriados: CERRADO</b></div>
        </div>
    </>)
}

export default Writehorario