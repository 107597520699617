import React, { useRef, useState } from "react";
import "../components/payments.css";
import {
    REACT_APP_CLAVESITIO,
    REACT_APP_CLAVESECRETA,
} from "../store/consts/consts";
import Reaptcha from "reaptcha";
import Swal from "sweetalert2";
import appsnew from "../images/preciosapp.jpg";
import apptv from "../images/iconmplay.png";
import appfen from "../images/iconfencin.png";
import appsfen from "../images/02newdispo.jpg";
import appsmed from "../images/mediafire.jpg";
import appscin from "../images/fenmed.jpg";
import ReactPlayer from "react-player";
import Writehorario from "./Horario";
import Helmet from 'react-helmet';
import axios from "axios";

const InstallAes = () => {
    const [term, setTerm] = useState("");
    const [text, setText] = useState("");
    const [errmessage, setErrMessage] = useState("por favor complete el captcha");

    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then((res) => {
            setCaptchaToken(res);
        });
    };

    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            /* ✅ Copied successfully */
        } catch (e) {
            /* ❌ Failed to copy (insufficient permissions) */
        }
    }
    const writeatencion = () => {
        return (
            <>
                <div className="contentatencion">
                    <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
                </div>
            </>
        );
    };

    const [usdprice, setUsdprice] = useState(0)

    const preciodolar = () => {
        axios.get("https://api.bluelytics.com.ar/v2/latest").then((response) => {
            setUsdprice(response.data.oficial.value_sell);
        });
        return usdprice.toLocaleString(undefined, { minimumFractionDigits: 2 })
    }

    const calcular = (canti) => {
        return (usdprice * parseFloat(canti)).toLocaleString(undefined, { minimumFractionDigits: 2 })
    }

    return (

        <div>
            <Helmet>
                <title>Install Aes</title>
            </Helmet>
            <Writehorario />
            {writeatencion()}
            <div class="card text-white bg-primary mb-3">
                <div class="card-header">Instrucciones de instalación</div>
                <div class="card-body">
                    <h5 class="card-title">Cómo instalar apps en equipos andorid</h5>
                    <p class="card-text">
                        Instalar apps en Convertidor android (TV Box), tablets android,
                        celulares android y emuladores Android para Windows (recomendado Nox
                        Player).{" "}
                        <a href="#tvbox" className="text-light">
                            Instrucciones
                        </a>
                        <br />
                        Instalar apps en en TELEVISORES y dispositivos AndroidTV / GoogleTV / Fire TV.{" "}
                        <a href="#androidtv" className="text-light">
                            Descargue PDF
                        </a>
                        <br />
                    </p>
                </div>
            </div>
            <div id="tvbox" className="row">
                <div class="card border-primary col-md-6">
                    <div className="card-header"><h3>Descargar app de Tel3, Cin3 y S3ri3s</h3></div>
                    <div className="showcase">
                        <div className="contentinstallapp">
                            <p className="text-dark bg-info">APP multimedia AES:</p>
                            <b>a)</b> ir al navegador web 🌐 y
                            escribe el siguiente link, en la barra de direcciones{" "}
                            <button
                                className="boton"
                                onClick={() => window.open("https://km210.com/apk/v3.apk")}
                            >
                                👉 https://km210.com/apk/v3.apk 👈
                            </button>{" "}

                            <br />
                            (NO usar la barra de búsqueda).
                            <br />
                            En <b>celu o tablet android</b>, solo{" "}
                            <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.
                            <br />
                            <b>b)</b>Aceptar la descarga de archivo .apk
                            <br />
                            <b>c)</b> Al terminar de descargar, clic en abrir/open (para
                            instalar) en mensaje emergente. <br />
                            Si el mensaje se borra (abrir/open) muy rápido, salga del
                            navegador y valla a las apps del equipo, buscar file
                            manager/archivos y alli la carpeta download y buscar el archivo
                            multivision.apk <br />
                            (si tiene varios archivos llamados tv con números entre
                            paréntesis, aquel con el numero mas grande será el último bajado).
                            <br />
                            <b>d)</b> abre la app e inicia sesión
                            <br />
                            <b>e)</b> A disfrutar!
                        </div>
                    </div>
                </div>
                <div class="card border-primary col-md-6">
                    Si no posee equipo android, consulte por posibilidad en su TV
                    <div className="formulario">
                        Precios segun dispositivos y contenido: <br /><br />
                        1 dispositivo: USD 7,50 - $ {calcular(7.5)}<br />
                        1 dispositivos c/XXX: USD 8,00 - $ {calcular(8)}<br />
                        2 dispositivos: USD 8,50 - $ {calcular(8.5)}<br />
                        2 dispositivos c/XXX: USD 9,00 - $ {calcular(9)}<br />
                        3 dispositivos: USD 9,50 - $ {calcular(9.5)}<br />
                        3 dispositivos c/XXX: USD 10,00 - $ {calcular(10)}<br />
                        <br/>(🇦🇷) precio USD oficial: $ {preciodolar()}
                    </div>
                </div>
            </div>
            <div id="androidtv" className="contentpdf">
                Para instalar las apps en <b>TELEVISORES</b> y dispositivos{" "}
                <b>AndroidTV / GoogleTV / Fire TV</b> descargue el siguiente{" "}
                <a
                    href="https://sib-2000.com.ar/docs/tutoa3s.pdf"
                    download="tutoa3s.pdf"
                    target="_blank" rel="noopener noreferrer"
                >
                    <button className="boton">Documento PDF</button>
                </a>
            </div>
            <br />
            <div className="contentnota">
                NOTA: <b>No nos hacemos responsables</b> por cualquier inconveniente *o
                daño al equipo* al instalar la app. Si usted no es avezado en el tema,
                le solicitamos que, *por favor contacte a su asesor/técnico IT de
                cabacera*, para poder recibir asesoramiento en los pasos de esta guia.
            </div>

            <hr />
            {/*         {writehora()} */} <Writehorario />
            {writeatencion()}
        </div>
    );
};

export default InstallAes;
