import axios from "axios";
import { useEffect, useState } from "react";

const jsonbtvlocu = "https://inliveserver.com:2000/json/stream/12000";
const jsonbtv = "https://cp.panelchs.com:2020/json/stream/8206";
const jsoneps = "https://cp.panelchs.com:2020/json/stream/8146";

const Stats = () => {
  const [viewbtvlc, setViewbtvlc] = useState(null);
  const [vieweps, setVieweps] = useState(null);
  const [viewbtv, setViewbtv] = useState(null);

  useEffect(() => {
    axios.get(jsonbtvlocu).then((response) => {
      setViewbtvlc(response.data);
    });
    axios.get(jsonbtv).then((response) => {
      setViewbtv(response.data);
    });
    axios.get(jsoneps).then((response) => {
      setVieweps(response.data);
    });
  }, []);

  if (!viewbtvlc) return null;
  if (!viewbtv) return null;
  if (!vieweps) return null;

  return (
    <div>
      <div class="card text-white bg-primary mb-3">
        <div class="card-header">Estadísticas de streaming</div>
        <div class="card-body">
          <h5 class="card-title">Streamings video</h5>
          <p>
          <h3>Bragado TV</h3>
          Server: {viewbtv.status === true ? "Habilitado" : "deshabilitado"},
          Viendo: {viewbtv.connections}, Bitrate {viewbtv.bitrate}
        </p>
        <h3>Bragado TV lc</h3>
        <p>
          Server: {viewbtvlc.status === true ? "Habilitado" : "deshabilitado"},
          Viendo: {viewbtvlc.connections}, Bitrate {viewbtvlc.bitrate}
        </p>
        <h3>EPS TV</h3>
        <p>
          Server: {vieweps.status === true ? "Habilitado" : "deshabilitado"},
          Viendo: {vieweps.connections}, Bitrate {vieweps.bitrate}
        </p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
