// require('dotenv').config()
export const REACT_APP_API = process.env.REACT_APP_API //endpoint
export const REACT_APP_SMSKEY = process.env.REACT_APP_SMSKEY // keysms
export const REACT_APP_CLOUPRESET=process.env.REACT_APP_CLOUPRESET // keysms
export const REACT_APP_CLOUUSER=process.env.REACT_APP_CLOUUSER // keysms
export const REACT_APP_GEO=process.env.REACT_APP_GEO // keysms
export const REACT_APP_API_HOOK=process.env.REACT_APP_API_HOOK // para hook avisamail
export const REACT_APP_API_WA=process.env.REACT_APP_API_WA // url de whatsapp
export const REACT_APP_DEV_TOOLS=process.env.REACT_APP_DEV_TOOLS // url de whatsapp
export const REACT_APP_CLOUDINARY_NAME=process.env.REACT_APP_CLOUDINARY_NAME // url de cloudinary name

//productos
export const productsEndpoint= REACT_APP_API + 'products/';
export const productByIdEndpoint = productsEndpoint + ':id';
export const productByCatEndpoint = productsEndpoint + 'bycat/:category';
export const addProductEndpoint = productsEndpoint + 'add';
export const stockProductEndpoint = productsEndpoint + 'stock'
export const modifyProductEndpoint = productsEndpoint + 'update';

// categories

export const categoriesEndpoint= REACT_APP_API + 'categories/';

// brands

export const brandsEndpoint= REACT_APP_API + 'brands/';

//carrito

export const productId = REACT_APP_API + 'product/'

// configs y users

export const usersEndpoint= REACT_APP_API + 'users/';

export const configByIdEndpoint = REACT_APP_API + 'configs/';

// webpush

export const webpushEndpoint= REACT_APP_API + 'push/';
export const suscribeEndpoint= REACT_APP_API + 'push/subscription';

// recaptchaGoogle

export const REACT_APP_CLAVESITIO = process.env.REACT_APP_CLAVESITIO
export const REACT_APP_CLAVESECRETA = process.env.REACT_APP_CLAVESECRETA
